<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Call For Papers" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-4 ps-4">
            <h3>Call for Papers:</h3>
            <div class="ps-3">
              <p>
                International Journal Of Scientific Research In Engineering And Technology (IJSREAT) is having ISSN:
                2583-1240 (online), Bi-Monthly international journal by 5th Dimension Research Publication.
              </p>
            </div>
            <div class="ps-3">
              <p>It is an academic, online, open access, Peer-reviewed international journal. It aims to
                publish original, theoretical, and practical advances in Computer Science & Engineering, Information
                Technology, Electrical and Electronics Engineering, Electronics and Telecommunication, Mechanical
                Engineering, Civil Engineering, Textile Engineering, and all interdisciplinary streams of Engineering
                Sciences. The board of committee of IJSREAT will review all submitted papers.</p>
            </div>
            <div class="ps-3">
              <p>Papers for the regular issues of the Journal can be submitted, around the year, electronically at
                editorinchief@ijsreat.com. After the final acceptance of the paper, based upon the detailed review
                process, it will immediately be published online. However, the assignment of the report to a specific
                Volume / Issue (see archive) of the Journal will be taken up by the Editorial Board later; and the author
                will be intimated accordingly. For Theme Based Special Issues, time-bound Special Calls for Papers will be
                informed, and the same will only apply to that specific issue. </p>
            </div>
            <h5>For the author’s convenience, we strongly follow the following steps.</h5>
            <div>
              <ul>
                <li>
                  <p> The response will be given within 12 hours.</p>
                </li>
                <li>
                  <p> The paper will be reviewed within Two days.</p>
                </li>
                <li>
                  <p>The author will get their Acceptance/Rejection Notification within three days of submitting the
                    paper.
                  </p>
                </li>
                <li>
                  <p>All the accepted papers will be open and accessible with a full PDF download.</p>
                </li>
                <li>
                  <p>All Authors will get a Soft copy of the certificate, acceptance letter, and reviewer evaluation grading.</p>
                </li>
              </ul>
            </div>
            <h6><b>Submit your article: </b><i>editorinchief@ijsreat.com</i>(or) Online submission</h6>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}


.mission-of-journal ul li {
  margin-left: -12px;
}
</style>
  