<template>
  <div>
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJEEE</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=4">
                <button class="default-btn mr-3">Submit Your Article<span></span></button>
              </a>
                </div>
              </div>
              <div class="row gy-4 pt-4">
                <h3>Welcome To INDJEEE :</h3>
                <div>
                  <div class="ps-3">
                    <p>
                      Indian Journal of Electrical and Electronics Engineering (INDJEEE) is a peer-reviewed open-access
                      journal in electrical engineering, economics, and its applications, with a specific interest in
                      power systems. INDJEEE publishes reviews, research papers, technical notes, and communications for
                      researchers, professionals, industrial practitioners, scientists, electrical/electronic engineers,
                      technologists, graduate students, and scholars. Published papers include original research results
                      of both theoretical and experimental nature on the following key topics: electric power systems,
                      power electronics, and applications, materials and devices, micro grids, energy conversion
                      technologies, and procedures. High-quality assistance is welcome from both academia and industry
                      working in electrical engineering. All research papers published in this journal undergo rigorous
                      peer review, in addition to initial author screening and anonymous refereeing by independent expert
                      judges.
                    </p>
                  </div>
                </div>
                <div>
                  <h5>Research Articles: </h5>
                  <div class="ps-3">
                    <p> These should describe new, carefully confirmed findings, innovative & creative research ideas, and
                      experimental techniques should be given in adequate detail for others to verify the work. The length
                      of a full paper should be the minimum required to describe and interpret the work. It also includes
                      personalized appraisal papers on the research work carried out at the author's laboratory based on
                      the author's published work.</p>
                  </div>

                </div>
                <div class="particulars">
                  <h5>Journal particulars :</h5>
                  <div class="row">
                    <div class="col">
                      <ul>
                        <li>
                          <p>
                            <b>Title :</b>Indian Journal of Electrical and
                            Electronics Engineering(INDJEEE)
                          </p>
                        </li>
                        <li>
                          <p><b>Frequency :</b>3 issues per year</p>
                        </li>
                        <li>
                          <p><b>ISSN :</b>Applied</p>
                        </li>
                        <li>
                          <p>
                            <b>Publisher :</b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Editor-in-Chief :</b>Dr.S.Sivaganesan (Professor
                            & Head)
                          </p>
                        </li>
                        <li>
                          <p>
                            <b>Copyright :</b>Fifth Dimension Research
                            Publication (P) Ltd.
                          </p>
                        </li>
                        <li>
                          <p><b>Starting Year :</b>2024</p>
                        </li>
                      </ul>
                    </div>
                    <div class="col">
                      <ul>
                        <li>
                          <p>
                            <b>Subject :</b> Electrical and Electronics
                            Engineering
                          </p>
                        </li>
                        <li>
                          <p><b>Language :</b> English</p>
                        </li>
                        <li>
                          <p><b>Publication Format :</b> Online</p>
                        </li>
                        <li>
                          <p><b>Email Id :</b> editor_indjeee@fdrpjournals.org</p>
                        </li>
                        <li>
                          <p><b>Mobile No :</b> +91 9840521421</p>
                        </li>
                        <li>
                          <p><b>Website :</b> https://fdrpjournals.org/indjeee</p>
                        </li>
                        <li>
                          <p>
                            <b>Address :</b> Fifth Dimension research
                            Publication (P) Ltd. No: 38D5F, Rajaji nagar,
                            Ariyalur-621713. Tamilnadu, India.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="container mt-1"> 
                  <h5 class="pb-2">Why Choose INDJEEE Journal :</h5>
                  <div class="row"> 
                    <div class="col-lg-6 col-md-12 col-sm-12"> 
                      <ul> 
                        <li><p><b>Publishing Frequency:</b> 3 Issue per year</p></li>
                        <li><p><b>Peer reviewed refereed: </b>Journal</p></li>
                        <li><p>Easy, fast, transparent paper publication process</p></li>
                        <li><p>Registered with Ministry of MSME, Govt. of India</p></li>
                        <li><p>Open Access Journal</p></li>
                        <li><p>Strict Plagiarism</p></li>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12"> 
                      <ul> 
                        <li><p>Simple steps for publication of research and review articles</p></li>
                        <li><p>High Visibility & Indexing</p></li>
                        <li><p><b>Publication Ethics:</b> COPE's Best Practice Guidelines</p></li>
                        <li><p><b>Publisher License under:</b> Creative By NC ND</p></li>
                      </ul>
                    </div>
                  </div>
                  </div>
                  <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=4">
                <button class="default-btn mr-3">Submit Your Article<span></span></button>
              </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ProjectsDetailsContent",
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjeee" },
        { id: 2, name: "Aim & Scope", to: "/indjeee/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjeee/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjeee/Guidance-For-Ems-Indjeee"},
        { id: 5, name: "Paper Status", to: "/indjeee/paper-status" },

        { id: 6, name: "Current Issue", to: "/indjeee/current-issue" },
        { id: 7, name: "Archives", to: "/indjeee/archives" },
        { id: 8, name: "Editorial Board", to: "/indjeee/editorial-board" },
        { id: 9, name: "Topics", to: "/indjeee/topics" },
        { id: 10, name: "Call for papers", to: "/indjeee/call-for-paper" },
        { id: 11, name: "Instruction for Authors", to: "/indjeee/instruction-for-authors" },


        { id: 12, name: "Ethics & Policies", to: "/indjeee/ethics-and-policies" },
        { id: 13, name: "Indexing & Abstracting", to: "/indjeee/indexing-abstracting", },
        { id: 14, name: "Impact Factor", to: "/indjeee/impact-factors" },
        { id: 15, name: "Article Processing Charges", to: "/indjeee/article-processing-charges", },
        { id: 16, name: "Downloads", to: "/indjeee/downloads" },
        { id: 17, name: "FAQs", to: "/indjeee/FAQs" },
        { id: 18, name: "Peer Review Policy", to: "/indjeee/peer-review-policy" },
        { id: 19, name: "Contact Us", to: "/indjeee/indjeee-contact" },

      ],
    };
  },
};
</script>
  
<style scoped lang="scss">
h3 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

.particulars ul li {
  padding-bottom: 5px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.containerbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 120px 50px 120px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;

  .imageBox {
    width: 120px;
    height: auto;
    margin-right: 50px;
    border-radius: 5px;
  }
}

@media screen and (max-width:1199px) {
  .containerbox {
    flex-direction: column;

    .imageBox {
      margin-bottom: 50px;
    }

    .contentBox {
      margin-left: 0px !important;
    }
  }
}</style>
  
