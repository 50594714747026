<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Instruction For Authors" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="row gy-4 pt-4 paraIndent instructionIjsreat">
                <div class="intruction-for-author-content">
                  <h3 class="headingThree">Instruction For Authors :</h3>
                  <p class="ps-3">
                    Below are the mandatory criteria for any article to be considered for publication in the International
                    Journal of Scientific Research in Engineering and Technology. Please adhere to these criteria to avoid
                    rejection of the article by the editorial team.
                  </p>
                  <h6>
                    The article adheres to the manuscript preparation guidelines explained below:
                  </h6>
                  <ul>
                    <li>
                      <p>The article should be in Microsoft Word format only.</p>
                    </li>
                    <li>
                      <p>
                        Articles should be written in single-column format, using Times New Roman font, 11-point font
                        size. Keep the layout of the text as simple as possible.
                      </p>
                    </li>
                    <li>
                      <p>
                        Equations and formulas should be readable, preferably written using equation editing software
                        (E.g., Math Type). Authors have to provide the fonts used for creating the equations/procedures.
                      </p>
                    </li>
                    <li>
                      <p>
                        All figures provided are of high resolution, preferably 300 dpi.
                      </p>
                    </li>
                    <li>
                      <p>References should be in Times New Roman.</p>
                    </li>
                  </ul>
                </div>
                <div class="preparation-guidelines mt-2">
                  <h5>Manuscript Preparation Guidelines :</h5>
                  <p class="ps-3">
                    Articles submitted to the International Journal of Scientific Research in Engineering and Technology
                    should conform to the guidelines below. Please review the author checklist supplied at the end of this
                    document before submitting. The following list outlines the subjects that will be covered
                    chronologically in the article:
                  </p>
                  <ul>
                    <li>
                      <p>Title</p>
                    </li>
                    <li>
                      <p>Abstract</p>
                    </li>
                    <li>
                      <p>Keywords</p>
                    </li>
                    <li>
                      <p>Introduction</p>
                    </li>
                    <li>
                      <p>
                        Concept headings (include statistical methodology, if
                        any)
                      </p>
                    </li>
                    <li>
                      <p>Discussion</p>
                    </li>
                    <li>
                      <p>Conclusion</p>
                    </li>
                    <li>
                      <p>Acknowledgements (If any)</p>
                    </li>
                    <li>
                      <p>References</p>
                    </li>
                  </ul>
                </div>
                <div class="electronic-formats mt-2">
                  <h5>Electronic Formats :</h5>
                  <p class="ps-3">
                    Authors must send their articles in MS Word (.doc) format. In case of any difficulty with the
                    manuscript submission process or concern regarding the suitability of your files, please get in touch
                    with us at
                    <b>editorinchief@ijsreat.com.</b>
                  </p>
                </div>
                <div class="article-titles">
                  <h5>Article Title :</h5>
                  <div class="ps-3">
                    <p>
                      The title should be brief and relevant to the article's content. In the title, avoid using
                      abbreviations. Titles should be given in title case, meaning all words are capitalized except for
                      prepositions, articles, and conjunctions. Italics should be used for all plant names.
                    </p>
                  </div>
                </div>

                <div class="author-name">
                  <h5>Author Names & Affiliations :</h5>
                  <div class="ps-3">
                    <p>
                      Provide first names or initials (if used), middle names or initials (if used), and surnames for all
                      authors. Affiliation details should include—department, university or organization, city, state, and
                      country for all authors. One of the authors should be designated as the corresponding author with an
                      asterisk (*) against their name. Only the corresponding author's email address should be provided in
                      the article. It is the corresponding author's responsibility to ensure that the author list and the
                      summary of the author's contributions to the study are accurate and complete.
                    </p>
                  </div>
                </div>
                <div class="abstract-keyword">
                  <h5>Abstract & Keywords :</h5>
                  <div class="ps-3">
                    <p>
                      The abstract introduces the topic and should be no more than 300 words long. It should describe the
                      methodologies utilized without delving into methodological detail, and it should summarize the most
                      relevant findings. Please do not include any sources in the abstract and, if feasible, avoid
                      utilizing acronyms.
                    </p>
                    <p>
                      For indexing reasons, authors should give 4-6 keywords. Keywords should be capitalized and separated
                      by a comma. Avoid using generic and plural phrases and several notions (for example, 'and,' 'of').
                    </p>
                  </div>
                </div>
                <div class="headings">
                  <h5>Headings :</h5>
                  <div class="ps-3">
                    <p>
                      The headers and subheadings, beginning with "1. Introduction," are written in both uppercase and
                      lowercase characters, bolded, and flush left aligned. From the Introduction through the
                      Acknowledgements, each heading is given a sequential number starting with 1, 2, 3, etc. The
                      numbering for subheadings is 1.1, 1.2, etc. To further separate a subsection, the numbers 1.1.1,
                      1.1.2, etc.
                    </p>
                    <p>
                      The font size for the heading is 11 points in boldface, and subsections with 10 points and not bold.
                      Do not underline any of the headings or add dashes, colons, etc. (10)
                    </p>
                  </div>
                </div>
                <div class="abbreviation">
                  <h5>Abbreviations :</h5>
                  <div class="ps-3">
                    <p>
                      All abbreviations should be specified in the text when they are first used, along with the
                      abbreviation in parentheses. Magnetic Resonance Imaging, for example (MRI).
                    </p>
                  </div>
                </div>
                <div class="units-symbols">
                  <h5>Units and Symbols :</h5>
                  <div class="ps-3">
                    <p>
                      Symbols should be used when referring to alpha, beta, mu, etc. (Ex: α, β, µ, etc.). All units follow
                      the International System of Units (SI units).
                    </p>
                  </div>
                </div>
                <div class="figures">
                  <h5><b>Figures:</b> General guidelines</h5>
                  <div class="ps-3">
                    <p>
                      <b>Format and resolution of the figures: </b> TIFF, JPG, or EPS files in high quality are required
                      from the authors. Figures must be produced with a minimum resolution of 300 ppi.
                    </p>
                    <p>
                      <b>File size:</b> The file sizes should not exceed 20 MB.
                    </p>
                    <p>
                      <b>File naming:</b> Figure files should be named with the author's serial number and last name
                      included. Figure 1 should be titled "Figure 1. Marley" if the author's name is Bob Marley.
                    </p>
                    <p>
                      <b>Citation:</b>All figures must be cited in the text, and authors should indicate where they should
                      be inserted.
                    </p>
                    <p>
                      <b>Figure captions:</b> These must be included in the text and provided sequentially at the end of
                      the article. The captions should be short, having 10-15 words in sentence case style. <b>Eg. Figure
                        1.</b> Percentage of
                      detection rate vs. number of nodes.
                    </p>
                    <p>
                      <b>Permissions:</b>Authors should obtain permission from authors for copyright figures and tables
                      before submitting them to the Journal of Science and Technology.
                    </p>
                    <p>
                      All figures will be released under the Creative Commons Attribution License, allowing them to be
                      freely used, disseminated, and built upon with due acknowledgment. Please only submit previously
                      copyrighted statistics if you have permission from the copyright owners to publish them under the
                      CCAL approval.
                    </p>
                  </div>
                </div>
                <div class="tables">
                  <h5>Tables: General guidelines</h5>
                  <div class="ps-3">
                    <p>
                      Tables should be included in the text file at the end of the article.
                    </p>
                    <p>
                      All tables should have a concise title and be written as Table 1 with a period (.).
                    </p>
                    <p><b>Eg.</b> Table 1. Stimulation settings.</p>
                    <p>
                      Footnotes can be used to explain abbreviations. Tables extending beyond 1 page should be avoided.
                    </p>
                  </div>
                </div>
                <div class="acknowledgments">
                  <h5>Acknowledgments :</h5>
                  <div class="ps-3">
                    <p>
                      Individuals who contributed to the work but did not meet the authorship standards should be
                      acknowledged in the Acknowledgements, along with their contributions. Writers are asked to ensure
                      that everybody identified in the Acknowledgements agrees to be so named.
                    </p>
                  </div>
                </div>
                <div class="references">
                  <h5>References :</h5>
                  <div class="ps-3">
                    <p>
                      The reference list should only contain works that have been published or approved. It is appropriate
                      to mention meetings, conference sessions, abstracts, or articles that were submitted but have yet to
                      be accepted.
                    </p>
                    <p>
                      <b>In text citations:</b> References cited in the text should be in Times New Roman.
                    </p>
                    <p>
                      <b>Reference List:</b>The books cited in your text should be listed here. Your text should contain
                      it at the conclusion. It ought to be put in order numerically by citation. These are some examples.
                    </p>
                  </div>
                </div>
                <div class="overview">
                  <h5>Overview of the Production Process :</h5>
                  <div class="ps-3">
                    <p>
                      Upon acceptance of the work, the manuscript will be edited and inspected for missing materials or
                      discrepancies. The production staff will contact the authors and request the missing material in
                      their manuscript. This will be the last opportunity for authors to make changes to the text. When
                      the modified files have been finalized, they will be typeset to create the first proofs. Writers
                      will receive the first proofs of their pieces to address any outstanding issues. Writers are
                      instructed not to provide any revisions during the proofing process. They are asked to team with the
                      presentation team to ensure a smooth workflow and timely publication of the journal issue.
                    </p>
                  </div>
                </div>
                <div class="author-checklist">
                  <h5>Author Checklist before Submission :</h5>
                  <div class="ps-3">
                    <p>
                      Are you ready to submit your article? We recommend you check these items before uploading the
                      article on our website. In case of any difficulty in submitting your article, please get in touch
                      with us at
                      <b>editorinchief@ijsreat.com. </b>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <p>The manuscript adheres to guidelines.</p>
                    </li>
                    <li>
                      <p>All author affiliations provided.</p>
                    </li>
                    <li>
                      <p>The corresponding author’s email address is provided.</p>
                    </li>
                    <li>
                      <p>
                        The document includes calls out for each figure and table.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>

