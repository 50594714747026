import {
    createWebHistory,
    createRouter
} from "vue-router";
//GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
// All Pages
import HomePageSix from '../components/Pages/HomePageSix'
import SubmitYourJournal from '../components/Pages/SubmitYourJournal'
import TermsAndConditions from '../components/Pages/TermsConditions'
import PrivacyPolicy from '../components/Pages/PrivacyPolicy'
import CallForPaperMain from "../components/Pages/CallForPaper.vue"
import BlogPageOne from '../components/Pages/BlogPageOne'
import BlogPageTwo from '../components/Pages/BlogPageTwo'
import BlogDetailsPage from '../components/Pages/BlogDetailsPage'
import ContactPage from '../components/Pages/ContactPage'
import InstructionForAuthors from '../components/Pages/InstructionForAuthors'
import IndexingAbstracting from '../components/Pages/IndexingAbstracting'
import IJIRE from '../components/Pages/Ijire'
import JournalContent from '../components/Journals/Ijire/JournalContent.vue'
import AboutUs from '../components/AboutUs/AboutUs.vue'
import Aim_Scope from '../components/Journals/Ijire/AimScope.vue' // IJIRE
import Editorial_Board from '../components/Journals/Ijire/EditorialBoard.vue'
import GuidanceForEms from '../components/Journals/Ijire/GuidanceForEms.vue'
import Topics from '../components/Journals/Ijire/Topics.vue'
import CallForPaper from "../components/Journals/Ijire/CallForPaper.vue"
import InstructionForAuthorsIjire from "../components/Journals/Ijire/InstructionForAuthor.vue"
import Paper_Submission from '../components/Journals/Ijire/PaperSubmission.vue'
import CurrentIssue from "../components/Journals/Ijire/CurrentIssue.vue"
import Archives from '../components/Journals/Ijire/Archives.vue'
import Conference from '../components/Journals/Ijire/Conference.vue'
import EthicsAndPolicies from '../components/Journals/Ijire/EthicsAndPolcies.vue'
import Indexing_Abstracting from '../components/Journals/Ijire/Indexing.vue'
import Impact_Factor from '../components/Journals/Ijire/ImpactFactor.vue'
import ProcessingCharges from '../components/Journals/Ijire/ProcessingCharges.vue'
import Peer_Review_Policy from '../components/Journals/Ijire/PeerReviewPolicy.vue'
import Downloads_Ijire from '../components/Journals/Ijire/Downloads.vue'
import FAQs from '../components/Journals/Ijire/FAQs.vue'
import PaperStatus_Ijire from '../components/Journals/Ijire/PaperStatus.vue'
import IjireContact from '../components/Journals/Ijire/IjireContact.vue'

import Ijsreat from "../components/Pages/Ijsreat.vue" //IJSREAT
import Aim_scope_Ijsreat from "../components/Journals/Ijsreat/AimScope.vue"
import Guidance_For_Ems_Ijsreat from "../components/Journals/Ijsreat/GuidanceForEmsIjsreat.vue"
import Editorial_Board_Ijsreat from '../components/Journals/Ijsreat/EditorialBoard.vue'
import topics_ijsreat from '../components/Journals/Ijsreat/Topics.vue'
import CallForPaper_Ijsreat from "../components/Journals/Ijsreat/CallForPapers.vue"
import InstructionForAuthors_Ijsreat from "../components/Journals/Ijsreat/InstructionForAuthor.vue"
import Paper_Submission_Ijsreat from '../components/Journals/Ijsreat/PaperSubmission.vue'
import CurrentIssue_Ijsreat from "../components/Journals/Ijsreat/CurrentIssue.vue"
import Archives_Ijsreat from '../components/Journals/Ijsreat/Archives.vue'
import EthicsAndPolicies_Ijsreat from '../components/Journals/Ijsreat/EthicsPolicies.vue'
import Indexing_Abstracting_Ijsreat from '../components/Journals/Ijsreat/Indexing.vue'
import Impact_Factor_Ijsreat from '../components/Journals/Ijsreat/ImpactFactor.vue'
import ArticleProcessingCharges_Ijsreat from '../components/Journals/Ijsreat/ProcessingCharge.vue'
import Peer_Review_Policy_Ijsreat from '../components/Journals/Ijsreat/PeerReviewPolicy.vue'
import Downloads_Ijsreat from '../components/Journals/Ijsreat/Downloads.vue'
import FAQs_Ijsreat from '../components/Journals/Ijsreat/FAQs.vue'
import PaperStatus_Ijsreat from '../components/Journals/Ijsreat/PaperStatusIjsreat.vue'
import IjsreatContact from '../components/Journals/Ijsreat/IjsreatContact.vue'
import IjsreatConference from '../components/Journals/Ijsreat/Conference.vue'


import Ijrtmr from '../components/Pages/Ijrtmr.vue' // IJRTMR
import AimScope_Ijrtmr from '../components/Journals/Ijrtmr/AimScope.vue'
import Archives_Ijrtmr from '../components/Journals/Ijrtmr/Archives.vue'
import CallForPaper_Ijrtmr from '../components/Journals/Ijrtmr/CallForPapers.vue'
import Downloads_Ijrtmr from '../components/Journals/Ijrtmr/Downloads.vue'
import CurrentIssue_Ijrtmr from '../components/Journals/Ijrtmr/CurrentIssue.vue'
import EditorialBoard_Ijrtmr from '../components/Journals/Ijrtmr/EditorialBoard.vue'
import GuidanceForEms_Ijrtmr from '../components/Journals/Ijrtmr/GuidanceForEmsijrtmr.vue'
import EthicsPolicies_Ijrtmr from '../components/Journals/Ijrtmr/EthicsPolicies.vue'
import FAQs_Ijrtmr from '../components/Journals/Ijrtmr/FAQs.vue'
import ImpactFactor_Ijrtmr from '../components/Journals/Ijrtmr/ImpactFactor.vue'
import Indexing_Ijrtmr from '../components/Journals/Ijrtmr/Indexing.vue'
import InstructionForAuthor_Ijrtmr from '../components/Journals/Ijrtmr/InstructionForAuthor.vue'
import PaperSubmission_Ijrtmr from '../components/Journals/Ijrtmr/PaperSubmission.vue'
import PeerReviewPolicy_Ijrtmr from '../components/Journals/Ijrtmr/PeerReviewPolicy.vue'
import ProcessingCharge_Ijrtmr from '../components/Journals/Ijrtmr/ProcessingCharge.vue'
import Topics_Ijrtmr from '../components/Journals/Ijrtmr/Topics.vue'
import PaperStatus_Ijrtmr from '../components/Journals/Ijrtmr/PaperStatusIjrtmr.vue'
import IjrtmrContact from '../components/Journals/Ijrtmr/IjrtmrContact.vue'
import IjrtmrConference from '../components/Journals/Ijrtmr/ConferenceIjrtmr.vue'

import Indjeee from '../components/Pages/Indjeee.vue' //Indjeee
import AimScope_Indjeee from '../components/Journals/Indjeee/AimScope.vue'
import Guidance_For_Ems_Indjeee from '../components/Journals/Indjeee/GuidanceForEmsIndjeee.vue'
import Archives_Indjeee from '../components/Journals/Indjeee/Archives.vue'
import EditorialBoard_Indjeee from '../components/Journals/Indjeee/EditorialBoard.vue'
import CallForPaper_Indjeee from '../components/Journals/Indjeee/CallForPapers.vue'
import Downloads_Indjeee from '../components/Journals/Indjeee/Downloads.vue'
import CurrentIssue_Indjeee from '../components/Journals/Indjeee/CurrentIssue.vue'
import EthicsPolicies_Indjeee from '../components/Journals/Indjeee/EthicsPolicies.vue'
import FAQs_Indjeee from '../components/Journals/Indjeee/FAQs.vue'
import ImpactFactor_Indjeee from '../components/Journals/Indjeee/ImpactFactor.vue'
import Indexing_Indjeee from '../components/Journals/Indjeee/Indexing.vue'
import InstructionForAuthor_Indjeee from '../components/Journals/Indjeee/InstructionForAuthor.vue'
import paperSubmission_Indjeee from '../components/Journals/Indjeee/PaperSubmission.vue'
import PeerReviewPolicy_Indjeee from '../components/Journals/Indjeee/PeerReviewPolicy.vue'
import ProcessingCharge_Indjeee from '../components/Journals/Indjeee/ProcessingCharge.vue'
import Topics_Indjeee from '../components/Journals/Indjeee/Topics.vue'
import PaperStatus_Indjeee from '../components/Journals/Indjeee/PaperStatusIndjeee.vue'
import IndjeeeContact from '../components/Journals/Indjeee/IndjeeeContact.vue'

import Indjece from '../components/Pages/Indjece.vue' //INDJECE
import AimScope_Indjece from '../components/Journals/Indjece/AimScope.vue'
import Guidance_For_Ems_Indjece from '../components/Journals/Indjece/GuidanceForEmsIndjece.vue'
import Archives_Indjece from '../components/Journals/Indjece/Archives.vue'
import EditorialBoard_Indjece from '../components/Journals/Indjece/EditorialBoard.vue'
import CallForPaper_Indjece from '../components/Journals/Indjece/CallForPapers.vue'
import Downloads_Indjece from '../components/Journals/Indjece/Downloads.vue'
import CurrentIssue_Indjece from '../components/Journals/Indjece/CurrentIssue.vue'
import EthicsPolicies_Indjece from '../components/Journals/Indjece/EthicsPolicies.vue'
import FAQs_Indjece from '../components/Journals/Indjece/FAQs.vue'
import ImpactFactor_Indjece from '../components/Journals/Indjece/ImpactFactor.vue'
import Indexing_Indjece from '../components/Journals/Indjece/Indexing.vue'
import InstructionForAuthor_Indjece from '../components/Journals/Indjece/InstructionForAuthor.vue'
import PaperSubmission_Indjece from '../components/Journals/Indjece/PaperSubmission.vue'
import PeerReviewPolicy_Indjece from '../components/Journals/Indjece/PeerReviewPolicy.vue'
import ProcessingCharge_Indjece from '../components/Journals/Indjece/ProcessingCharge.vue'
import Topics_Indjece from '../components/Journals/Indjece/Topics.vue'
import PaperStatus_Indjece from '../components/Journals/Indjece/PaperStatusIndjece.vue'
import IndjeceContact from '../components/Journals/Indjece/IndjeceContact.vue'



import Indjcst from '../components/Pages/Indjcst.vue' /* INDJCST */
import AimScope_Indjcst from '../components/Journals/Indjcst/AimScope.vue'
import Guidance_For_Ems_Indjcst from '../components/Journals/Indjcst/GuidanceForEmsIndjcst.vue'
import Archives_Indjcst from '../components/Journals/Indjcst/Archives.vue'
import EditorialBoard_Indjcst from '../components/Journals/Indjcst/EditorialBoard.vue'
import CallForPaper_Indjcst from '../components/Journals/Indjcst/CallForPapers.vue'
import Downloads_Indjcst from '../components/Journals/Indjcst/Downloads.vue'
import CurrentIssue_Indjcst from '../components/Journals/Indjcst/CurrentIssue.vue'
import EthicsPolicies_Indjcst from '../components/Journals/Indjcst/EthicsPolicies.vue'
import FAQs_Indjcst from '../components/Journals/Indjcst/FAQs.vue'
import ImpactFactor_Indjcst from '../components/Journals/Indjcst/ImpactFactor.vue'
import Indexing_Indjcst from '../components/Journals/Indjcst/Indexing.vue'
import InstructionForAuthor_Indjcst from '../components/Journals/Indjcst/InstructionForAuthor.vue'
import PaperSubmission_Indjcst from '../components/Journals/Indjcst/PaperSubmission.vue'
import PeerReviewPolicy_Indjcst from '../components/Journals/Indjcst/PeerReviewPolicy.vue'
import ProcessingCharge_Indjcst from '../components/Journals/Indjcst/ProcessingCharge.vue'
import Topics_Indjcst from '../components/Journals/Indjcst/Topics.vue'
import PaperStatus_Indjcst from '../components/Journals/Indjcst/PaperStatusIndjcst.vue'
import IndjcstContact from '../components/Journals/Indjcst/IndjcstContact.vue'




const routes = [{
        path: '/',
        component: HomePageSix
    },
    {
        path: '/submit-your-journal',
        component: SubmitYourJournal
    },
    {
        path: '/terms-conditions',
        component: TermsAndConditions
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/blog-one',
        component: BlogPageOne
    },
    {
        path: '/blog-two',
        component: BlogPageTwo
    },
    {
        path: '/blog-details',
        component: BlogDetailsPage
    },
    {
        path: '/contact',
        component: ContactPage
    },
    {
        path: '/instruction-authors',
        component: InstructionForAuthors
    },
    {
        path: '/indexing-abstracting',
        component: IndexingAbstracting
    },
    {
        path: '/CallForPaper',
        component: CallForPaperMain
    },



    {
        path: '/journals',
        component: JournalContent
    },
    {
        path: '/aboutUs',
        component: AboutUs
    },
    // ======== IJIRE
    {
        path: '/ijire',
        component: IJIRE
    },
    {
        path: '/ijire/aim-scope',
        name: 'Aim_Scope',
        component: Aim_Scope
    },
    {
        path: '/ijire/editorial-board',
        name: 'Editorial_Board',
        component: Editorial_Board
    },
    {
        path: '/ijire/topics',
        name: 'Topics',
        component: Topics
    },
    {
        path: '/ijire/call-for-papers',
        name: 'CallForPaper',
        component: CallForPaper
    },
    {
        path: '/ijire/instruction-for-authors',
        name: 'InstructionForAuthorsIjire',
        component: InstructionForAuthorsIjire
    },
    {
        path: '/ijire/paper-submission',
        name: 'Paper_Submission',
        component: Paper_Submission
    },
    {
        path: '/ijire/current-issue',
        name: 'CurrentIssue',
        component: CurrentIssue
    },
    {
        path: '/ijire/archives',
        name: 'Archives',
        component: Archives
    },
    {
        path: '/ijire/conference',
        name: 'Conference',
        component: Conference
    },
    {
        path: '/ijire/GuidanceForEms',
        name: 'GuidanceForEms',
        component: GuidanceForEms
    },
    {
        path: '/ijire/indexing-abstracting',
        name: 'Indexing_Abstracting',
        component: Indexing_Abstracting
    },
    {
        path: '/ijire/ethics-and-policies',
        name: 'EthicsAndPolicies',
        component: EthicsAndPolicies
    },
    {
        path: '/ijire/impact-factors',
        name: 'Impact_Factor',
        component: Impact_Factor
    },
    {
        path: '/ijire/article-processing-charges',
        name: 'ArticleProcessingCharges',
        component: ProcessingCharges
    },
    {
        path: '/ijire/downloads',
        name: 'Downloads_Ijire',
        component: Downloads_Ijire
    },
    {
        path: '/ijire/FAQs',
        name: 'FAQs',
        component: FAQs
    },
    {
        path: '/ijire/peer-review-policy',
        name: 'Peer_Review_Policy',
        component: Peer_Review_Policy
    },
    {
        path: '/ijire/paper-status',
        name: 'PaperStatus_Ijire',
        component: PaperStatus_Ijire
    },
    {
        path: '/ijire/ijire-contact',
        name: 'IjireContact',
        component: IjireContact
    },

    // =============IJSREAT
    {
        path: '/ijsreat',
        name: 'Ijsreat',
        component: Ijsreat
    },
    {
        path: '/ijsreat/aim-scope',
        name: 'Aim_scope_Ijsreat',
        component: Aim_scope_Ijsreat
    },
    {
        path: '/ijsreat/guidance-for-ems-ijsreat',
        name: 'Guidance_For_Ems_Ijsreat',
        component: Guidance_For_Ems_Ijsreat
    },
    {
        path: '/ijsreat/editorial-board',
        name: 'Editorial_Board_Ijsreat',
        component: Editorial_Board_Ijsreat
    },
    {
        path: '/ijsreat/topics',
        name: 'topics_ijsreat',
        component: topics_ijsreat
    },
    {
        path: '/ijsreat/call-for-paper',
        name: 'CallForPaper_Ijsreat',
        component: CallForPaper_Ijsreat
    },
    {
        path: '/ijsreat/instruction-for-authors',
        name: 'InstructionForAuthors_Ijsreat',
        component: InstructionForAuthors_Ijsreat
    },
    {
        path: '/ijsreat/paper-submission',
        name: 'Paper_Submission_Ijsreat',
        component: Paper_Submission_Ijsreat
    },
    {
        path: '/ijsreat/current-issue',
        name: 'CurrentIssue_Ijsreat',
        component: CurrentIssue_Ijsreat
    },
    {
        path: '/ijsreat/archives',
        name: 'Archives_Ijsreat',
        component: Archives_Ijsreat
    },
    {
        path: '/ijsreat/ethics-and-policies',
        name: 'EthicsAndPolicies_Ijsreat',
        component: EthicsAndPolicies_Ijsreat
    },
    {
        path: '/ijsreat/indexing-abstracting',
        name: 'Indexing_Abstracting_Ijsreat',
        component: Indexing_Abstracting_Ijsreat
    },
    {
        path: '/ijsreat/impact-factors',
        name: 'Impact_Factor_Ijsreat',
        component: Impact_Factor_Ijsreat
    },
    {
        path: '/ijsreat/article-processing-charges',
        name: 'ArticleProcessingCharges_Ijsreat',
        component: ArticleProcessingCharges_Ijsreat
    },
    {
        path: '/ijsreat/downloads',
        name: 'Downloads',
        component: Downloads_Ijsreat
    },
    {
        path: '/ijsreat/FAQs',
        name: 'FAQs_Ijsreat',
        component: FAQs_Ijsreat
    },
    {
        path: '/ijsreat/peer-review-policy',
        name: 'Peer_Review_Policy_Ijsreat',
        component: Peer_Review_Policy_Ijsreat
    },
    {
        path: '/ijsreat/paper-status',
        name: 'PaperStatus_Ijsreat',
        component: PaperStatus_Ijsreat
    },
    {
        path: '/ijsreat/ijsreat-contact',
        name: 'IjsreatContact',
        component: IjsreatContact
    },
    {
        path: '/ijsreat/ijsreat-conference',
        name: 'IjsreatConference',
        component: IjsreatConference
    },
    //==================IJRTMR
    {
        path: '/ijrtmr',
        name: 'Ijrtmr',
        component: Ijrtmr
    },
    {
        path: '/ijrtmr/aim-scope',
        name: 'AimScope_Ijrtmr',
        component: AimScope_Ijrtmr
    },
    {
        path: '/ijrtmr/editorial-board',
        name: 'EditorialBoard_Ijrtmr',
        component: EditorialBoard_Ijrtmr
    },
    {
        path: '/ijrtmr/GuidanceForEms-Ijrtmr',
        name: 'GuidanceForEms_Ijrtmr',
        component: GuidanceForEms_Ijrtmr
    },
    {
        path: '/ijrtmr/topics',
        name: 'Topics_Ijsreat',
        component: Topics_Ijrtmr
    },
    {
        path: '/ijrtmr/call-for-paper',
        name: 'CallForPaper_Ijrtmr',
        component: CallForPaper_Ijrtmr
    },
    {
        path: '/ijrtmr/instruction-for-authors',
        name: 'InstructionForAuthor_Ijrtmr',
        component: InstructionForAuthor_Ijrtmr
    },
    {
        path: '/ijrtmr/paper-submission',
        name: 'PaperSubmission_Ijrtmr',
        component: PaperSubmission_Ijrtmr
    },
    {
        path: '/ijrtmr/current-issue',
        name: 'CurrentIssue_Ijrtmr',
        component: CurrentIssue_Ijrtmr
    },
    {
        path: '/ijrtmr/archives',
        name: 'Archives_Ijrtmr',
        component: Archives_Ijrtmr
    },
    {
        path: '/ijrtmr/conference',
        name: 'Conference_Ijrtmr',
        component: IjrtmrConference
    },
    {
        path: '/ijrtmr/ethics-and-policies',
        name: 'EthicsPolicies_Ijrtmr',
        component: EthicsPolicies_Ijrtmr
    },
    {
        path: '/ijrtmr/indexing-abstracting',
        name: 'Indexing_Ijrtmr',
        component: Indexing_Ijrtmr
    },
    {
        path: '/ijrtmr/impact-factors',
        name: 'ImpactFactor_Ijrtmr',
        component: ImpactFactor_Ijrtmr
    },
    {
        path: '/ijrtmr/article-processing-charges',
        name: 'ProcessingCharge_Ijrtmr',
        component: ProcessingCharge_Ijrtmr
    },
    {
        path: '/ijrtmr/downloads',
        name: 'Downloads_Ijrtmr',
        component: Downloads_Ijrtmr
    },
    {
        path: '/ijrtmr/FAQs',
        name: 'FAQs_Ijrtmr',
        component: FAQs_Ijrtmr
    },
    {
        path: '/ijrtmr/peer-review-policy',
        name: 'PeerReviewPolicy_Ijrtmr',
        component: PeerReviewPolicy_Ijrtmr
    },
    {
        path: '/ijrtmr/paper-status',
        name: 'PaperStatus_Ijrtmr',
        component: PaperStatus_Ijrtmr
    },
    {
        path: '/ijrtmr/paper-status',
        name: 'PaperStatus_Ijrtmr',
        component: PaperStatus_Ijrtmr
    },
    {
        path: '/ijrtmr/ijrtmr-contact',
        name: 'IjrtmrContact',
        component: IjrtmrContact
    },
    //======================= Indjeee
    {
        path: '/indjeee',
        name: 'Indjeee',
        component: Indjeee
    },
    {
        path: '/indjeee/aim-scope',
        name: 'AimScope_Indjeee',
        component: AimScope_Indjeee
    },
    {
        path: '/indjeee/Guidance-For-Ems-Indjeee',
        name: 'Guidance_For_Ems_Indjeee',
        component: Guidance_For_Ems_Indjeee
    },
    {
        path: '/indjeee/editorial-board',
        name: 'EditorialBoard_Indjeee',
        component: EditorialBoard_Indjeee
    },
    {
        path: '/indjeee/topics',
        name: 'Topics_Indjeee',
        component: Topics_Indjeee
    },
    {
        path: '/indjeee/call-for-paper',
        name: 'CallForPaper_Indjeee',
        component: CallForPaper_Indjeee
    },
    {
        path: '/indjeee/instruction-for-authors',
        name: 'InstructionForAuthor_Indjeee',
        component: InstructionForAuthor_Indjeee
    },
    {
        path: '/indjeee/paper-submission',
        name: 'paperSubmission_Indjeee',
        component: paperSubmission_Indjeee
    },
    {
        path: '/indjeee/current-issue',
        name: 'CurrentIssue_Indjeee',
        component: CurrentIssue_Indjeee
    },
    {
        path: '/indjeee/archives',
        name: 'Archives_Indjeee',
        component: Archives_Indjeee
    },
    {
        path: '/indjeee/ethics-and-policies',
        name: 'EthicsPolicies_Indjeee',
        component: EthicsPolicies_Indjeee
    },
    {
        path: '/indjeee/indexing-abstracting',
        name: 'Indexing_Indjeee',
        component: Indexing_Indjeee
    },
    {
        path: '/indjeee/impact-factors',
        name: 'ImpactFactor_Indjeee',
        component: ImpactFactor_Indjeee
    },
    {
        path: '/indjeee/article-processing-charges',
        name: 'ProcessingCharge_Indjeee',
        component: ProcessingCharge_Indjeee
    },
    {
        path: '/indjeee/downloads',
        name: 'Downloads_Indjeee',
        component: Downloads_Indjeee
    },
    {
        path: '/indjeee/FAQs',
        name: 'FAQs_Indjeee',
        component: FAQs_Indjeee
    },
    {
        path: '/indjeee/peer-review-policy',
        name: 'PeerReviewPolicy_Indjeee',
        component: PeerReviewPolicy_Indjeee
    },
    {
        path: '/indjeee/paper-status',
        name: 'PaperStatus_Indjeee',
        component: PaperStatus_Indjeee
    },
    {
        path: '/indjeee/indjeee-contact',
        name: 'IndjeeeContact',
        component: IndjeeeContact
    },
    //===========================INDJECE

    {
        path: '/indjece',
        name: 'Indjece',
        component: Indjece
    },
    {
        path: '/indjece/aim-scope',
        name: 'AimScope_Indjece',
        component: AimScope_Indjece
    },
    {
        path: '/indjece/guidance-for-ems',
        name: "Guidance_For_Ems_Indjece",
        component: Guidance_For_Ems_Indjece
    },
    {
        path: '/indjece/editorial-board',
        name: 'EditorialBoard_Indjece',
        component: EditorialBoard_Indjece
    },
    {
        path: '/indjece/topics',
        name: 'Topics_Indjece',
        component: Topics_Indjece
    },
    {
        path: '/indjece/call-for-paper',
        name: 'CallForPaper_Indjece',
        component: CallForPaper_Indjece
    },
    {
        path: '/indjece/instruction-for-authors',
        name: 'InstructionForAuthor_Indjece',
        component: InstructionForAuthor_Indjece
    },
    {
        path: '/indjece/current-issue',
        name: 'CurrentIssue_Indjece',
        component: CurrentIssue_Indjece
    },
    {
        path: '/indjece/paper-submission',
        name: 'PaperSubmission_Indjece',
        component: PaperSubmission_Indjece
    },
    {
        path: '/indjece/archives',
        name: 'Archives_Indjece',
        component: Archives_Indjece
    },
    {
        path: '/indjece/ethics-and-policies',
        name: 'EthicsPolicies_Indjece',
        component: EthicsPolicies_Indjece
    },
    {
        path: '/indjece/indexing-abstracting',
        name: 'Indexing_Indjece',
        component: Indexing_Indjece
    },
    {
        path: '/indjece/impact-factors',
        name: 'ImpactFactor_Indjece',
        component: ImpactFactor_Indjece
    },
    {
        path: '/indjece/article-processing-charges',
        name: 'ProcessingCharge_Indjece',
        component: ProcessingCharge_Indjece
    },
    {
        path: '/indjece/downloads',
        name: 'Downloads_Indjece',
        component: Downloads_Indjece
    },
    {
        path: '/indjece/FAQs',
        name: 'FAQs_Indjece',
        component: FAQs_Indjece
    },
    {
        path: '/indjece/peer-review-policy',
        name: 'PeerReviewPolicy_Indjece',
        component: PeerReviewPolicy_Indjece
    },
    {
        path: '/indjece/paper-status',
        name: 'PaperStatus_Indjece',
        component: PaperStatus_Indjece
    },
    {
        path: '/indjece/indjece-contact',
        name: 'IndjeceContact',
        component: IndjeceContact
    },

    {
        path: '/indjcst',
        name: 'Indjcst',
        component: Indjcst
    }, //IJCST
    {
        path: '/indjcst/aim-scope',
        name: 'AimScope_Indjcst',
        component: AimScope_Indjcst
    },
    {
        path: '/indjcst/guidance-for-ems-indjcst',
        name: 'Guidance_For_Ems_Indjcst',
        component: Guidance_For_Ems_Indjcst
    },
    {
        path: '/indjcst/editorial-board',
        name: 'EditorialBoard_Indjcst',
        component: EditorialBoard_Indjcst
    },
    {
        path: '/indjcst/topics',
        name: 'Topics_Indjcst',
        component: Topics_Indjcst
    },
    {
        path: '/indjcst/call-for-paper',
        name: 'CallForPaper_Indjcst',
        component: CallForPaper_Indjcst
    },
    {
        path: '/indjcst/instruction-for-authors',
        name: 'InstructionForAuthor_Indjcst',
        component: InstructionForAuthor_Indjcst
    },
    {
        path: '/indjcst/current-issue',
        name: 'CurrentIssue_Indjcst',
        component: CurrentIssue_Indjcst
    },
    {
        path: '/indjcst/paper-submission',
        name: 'PaperSubmission_Indjcst',
        component: PaperSubmission_Indjcst
    },
    {
        path: '/indjcst/archives',
        name: 'Archives_Indjcst',
        component: Archives_Indjcst
    },
    {
        path: '/indjcst/ethics-and-policies',
        name: 'EthicsPolicies_Indjcst',
        component: EthicsPolicies_Indjcst
    },
    {
        path: '/indjcst/indexing-abstracting',
        name: 'Indexing_Indjcst',
        component: Indexing_Indjcst
    },
    {
        path: '/indjcst/impact-factors',
        name: 'ImpactFactor_Indjcst',
        component: ImpactFactor_Indjcst
    },
    {
        path: '/indjcst/article-processing-charges',
        name: 'ProcessingCharge_Indjcst',
        component: ProcessingCharge_Indjcst
    },
    {
        path: '/indjcst/downloads',
        name: 'Downloads_Indjcst',
        component: Downloads_Indjcst
    },
    {
        path: '/indjcst/FAQs',
        name: 'FAQs_Indjcst',
        component: FAQs_Indjcst
    },
    {
        path: '/indjcst/peer-review-policy',
        name: 'PeerReviewPolicy_Indjcst',
        component: PeerReviewPolicy_Indjcst
    },
    {
        path: '/indjcst/paper-status',
        name: 'PaperStatus_Indjcst',
        component: PaperStatus_Indjcst
    },
    {
        path: '/indjcst/indjcst-contact',
        name: 'IndjcstContact',
        component: IndjcstContact
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }

]

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
        return {
            top: 0
        };
    },
});

export default router;
