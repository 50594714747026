<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Peer Review Policy" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container pt-4 ">
              <h3>Peer Review Policy: </h3>
              <div class="ps-3">
                <p>International Journal of Scientific Research in Engineering and Technology (IJSREAT) (Online), a
                  bi-monthly worldwide journal developed by academics, educators, engineers, and business leaders to
                  publish innovative ideas. International Journal of Scientific Research in Engineering and Technology,
                  diverse engineering disciplines, and so on is all components of the research outcomes and fundamental
                  progress. IJSREAT is a scholarly open, accessible, Peer Review Journal that assists
                  academics and students. IJSREAT allows academics and industry to propose innovative research and
                  applications in various engineering disciplines and advanced technologies.</p>
              </div>
              <div class="ps-3">
                <p> Reviewers play a central role in scholarly publishing. IJSREAT uses a peer review
                  process, which means that both the reviewer(s) and author(s) identities are concealed from the
                  reviewer(s), and vice versa, throughout the review process. This means that the reviewer(s) of the paper
                  won’t get to know the identity of the author(s), and the author(s) won’t get to know the identity of the
                  reviewer(s). Peer review helps validate research, establish a method by which it can be evaluated, and
                  increase networking possibilities within research communities. Despite criticisms, peer review is still
                  the only widely accepted method for research validation.
                  All submitted papers will be reviewed by a peer review process which may take a minimum of
                  01 to 03 weeks from the submission date. We advise all the author(s) not to submit the same paper to
                  multiple journals. It would be best if you waited for the review status of the paper.

                </p>
              </div>
              <h6 class="pb-3 pt-3">IJIRE is committed to prompt evaluation and publication of entirely accepted papers.
                To maintain a high-quality publication, all submissions undergo a rigorous review process. Characteristics
                of the peer review process are as follows:</h6>
              <div>
                <ul>
                  <li>
                    <p>Simultaneous submissions of the same manuscript to different journals will not be tolerated.</p>
                  </li>
                  <li>
                    <p>Manuscripts with contents outside the scope will not be considered for review.</p>
                  </li>
                  <li>
                    <p> Papers will be refereed by at least 3 or 4 experts (reviewers) as suggested by the editorial
                      board, of which 01 from India and the rest 02 or 03 from overseas.</p>
                  </li>
                  <li>
                    <p>In addition, Editors will have the option of seeking additional reviews when needed.</p>
                  </li>
                  <li>
                    <p>Authors will be informed when Editors decide further review is required. The journal's
                      Editors-in-Chief make all publication decisions based on the referees' reports (reviewer's report).
                    </p>
                  </li>
                  <li>
                    <p>Authors of papers that are not accepted are notified promptly.</p>
                  </li>
                  <li>
                    <p>All submitted manuscripts are treated as confidential documents. The review process
                      will review all submitted papers.</p>
                  </li>
                  <li>
                    <p> All manuscripts submitted for publication in IJSREAT were cross-checked for plagiarism software.
                      Manuscripts found to be plagiarized during the initial stages of review are out-rightly rejected and
                      not considered for publication in the journal.</p>
                  </li>
                  <li>
                    <p>Suppose a manuscript is found to be plagiarized after publication. In that case, the
                      Editor-in-Chief will conduct a preliminary investigation, possibly with the help of an appropriate
                      committee constituted for the purpose. If the manuscript is found to be plagiarized beyond the
                      acceptable limits, the journal will contact the author's Institute / College / University and
                      Funding Agency, if any.</p>
                  </li>
                </ul>
              </div>
              <h5 class="pb-2"> IJSREAT strictly uses following peer review process</h5>
              <h6 class="pb-2">Peer review process:</h6>
              <div class="ps-3">
                <p>Submissions to the International Journal of Scientific Research in Engineering and Technology (IJSREAT)
                  pass through a peer-review process. The criteria for publication in the International
                  Journal of Scientific Research in Engineering and Technology (IJSREAT) are as follows:</p>
              </div>
              <div>
                <ul>
                  <li>
                    <p>The paper reveals the findings of first-rate academic research.</p>
                  </li>
                  <li>
                    <p>The stated findings have yet to be published elsewhere.</p>
                  </li>
                  <li>
                    <p>The study adheres to all applicable ethical and scientific integrity requirements.</p>
                  </li>
                  <li>
                    <p>Experiments, statistics, and other analyses are carried out to a high technical quality and are
                      thoroughly reported.</p>
                  </li>
                  <li>
                    <p>The data is used to support the conclusions, which are presented correctly.</p>
                  </li>
                  <li>
                      <p>The paper is written in Standard English and presented in an understandable manner.</p>
                    </li>
                    <p>
                      Once the manuscripts have passed quality control, they are assigned to a member of the Editorial
                      Board (or an expert who is not a member) to conduct the peer-review process and decide to accept,
                      invite revision of, or reject the article.
                    </p>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
<script>
import Navbar from "../../Layout/Navbar.vue"
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}

p {
  text-align: justify;
  text-justify: inter-word;
}

.mission-of-journal ul li {
  margin-left: -12px;
}
</style>
  