<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Article Processing Charges" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>INDJCST</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container charges">
              <h3 class="pt-4">Article Processing Charges :</h3>
              <p>
                Open-access publication, so it is freely available online. The
                cost on publishing online editions, and to ensure the continuous
                appearance of the journal, the core committee has decided to
                charge a minimal fee from the authors after the acceptance of
                manuscript as follows;
              </p>
              <div>
                <h6>Case Report/Original Article/Review Article Fee:</h6>
              </div>
              <div class="pt-2">
                <h6>For Indian authors:</h6>
                <ul>
                  <li>
                    <p>
                      <b>Without DOI : </b><b class="text-danger">1000 INR</b><span class="price-span"><b> + 18% GST</b></span> 
                    </p>
                  </li>
                  <li>
                    <p><b>With DOI : </b><b class="text-danger">1200 INR</b><span class="price-span"><b> + 18% GST</b></span></p>
                  </li>
                </ul>
              </div>
              <div class="pt-2">
                <h6>For other than Indian authors:</h6>
                <ul>
                  <li>
                    <p><b>With DOI : </b><b class="text-danger">$ 50 USD</b></p>
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Authors of any country can convert USD to their respective
                  currency using the google currency calculator.
                </p>
              </div>
              <div class="pt-2">
                <h6>Article-processing charges are required for</h6>
                <ul>
                  <li>
                    <p>Journal Hosting System</p>
                  </li>
                  <li><p>Editorial management System</p></li>
                  <li><p>In-house copyediting and linguistic support</p></li>
                  <li><p>Pagination & typesetting</p></li>
                  <li>
                    <p>
                      Archiving, where appropriate, in an internationally
                      recognized, open access repositories.
                    </p>
                  </li>
                </ul>
              </div>
              <div class="pt-2">
                <h6>Note:</h6>
                <p>
                  1. After making payment the proof of payment should be
                  produced.
                </p>
                <p>
                  2. The manuscript fee is non-refundable, and if the author
                  wishes to withdraw the manuscript after paying the charges,
                  the fees, in that case, would not be refunded.
                </p>
                <p>
                  3. Detailed information about how to transfer the manuscript
                  fee will be sent on request.
                </p>
                <p>
                  If you face any problems or difficulties please contact us for
                  further guidance. Send us a copy of the receipt as proof of
                  the transfer of the money. Once the required transaction is
                  confirmed by our bank, we will intimate you promptly.
                </p>
              </div>
              <div class="pt-2">
                <h6>Waivers Policy</h6>
                <p>
                  Publication of an article in the journal is not contingent
                  upon the author's ability to pay the charges. Neither is
                  acceptance to pay the handling fee a guarantee that the paper
                  will be accepted for publication. Authors that may not be able
                  to pay can request the editorial office to reduce the fee to
                  an amount as per editorial office norms. We only accept
                  payment of handling fee/processing charges after the
                  manuscript has been accepted for publication.
                </p>
              </div>
              <div class="pt-2">
                <h6>Qualifying for a Waiver</h6>
                <p>
                  To help support researchers in low-income economies according
                  to World Bank, the Journal provides an automatic 50% waiver of
                  any Article Processing Charges for manuscripts where all
                  authors listed affiliation is in one of these countries.
                </p>
                <p>
                  Additionally, the Journal will provide a 50% reduction in any
                  APCs when the author(s) of an article does not have the
                  funding required to cover these charges and can provide one of
                  the following to demonstrate this:
                </p>
                <p>
                  Signed letter from author(s) suitable institutional
                  representative, such as institutional Head of Department, with
                  verifiable institutional email address confirming payment will
                  be made from author’s personal funds (all authors must provide
                  a letter to this effect)
                </p>
                <p>
                  Signed letter from retired or independent author(s) indicating
                  their lack of institutional association and confirming payment
                  will be made from personal funds (all authors on paper must be
                  retired or independent and confirm this)
                </p>
                <p>
                  Signed letter from the author(s) institution(s) confirming
                  budget constraints, indicating their funding body does not
                  cover publication charges or indicating the institutional
                  budgets are severely impacted. This must be signed by the
                  Institutional Head of Department with verifiable institutional
                  email address clearly stating the budget constraints (all
                  authors must provide a letter to this effect)
                </p>
              </div>

              <!-- <div class="row pt-4 gy-5">
                <div class="table-scroll text-center col-8 ps-3">
                  <table class="table table-striped text-center">
                    <thead style="background-color: #0000ff">
                      <tr>
                        <th scope="col">For Indian Authors(INR)</th>
                        <th scope="col">For Foreign Authors:(USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p class="text-danger"><b>Rs 499/-</b></p>
                        </td>
                        <td>
                          <p class="text-danger"><b>25 USD</b></p>
                        </td>
                      </tr>
                      <tr>
                        <td>Online publication</td>
                        <td>Online publication</td>
                      </tr>
                      <tr>
                        <td>Max 5 to 6 authors</td>
                        <td>Max 5 to 6 authors</td>
                      </tr>
                      <tr>
                        <td>Max 10 to 25 pages</td>
                        <td>Max 10 to 25 pages</td>
                      </tr>
                      <tr>
                        <td>E-Certificates (Free)</td>
                        <td>E-Certificates (Free)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="">
                  <h6>APC – Article processing charge includes :</h6>
                  <ul>
                    <li>
                      <p>Publication of one entire research paper Online.</p>
                    </li>
                    <li>
                      <p>
                        Individual Certificate Soft Copy to all authors of
                        paper.
                      </p>
                    </li>
                    <li>
                      <p>
                        Indexing, maintenance of link revolvers and journal
                        infrastructures.
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="ps-3">
                    <p>
                      <i
                        ><b>Note:</b> IJIRE Publication is a self-supporting
                        autonomous organization and we don’t get subsidizing
                        from any public of private substance. The article
                        Processing Charges are needed to meet operations
                        expenses such as employee salaries, Internet services,
                        office expenses, typesetting, indexing of articles,
                        web-hosting, server & website update and maintenance,
                        Electricity etc. Hence, the operation of the journal is
                        exclusively financed by the processing charges received
                        from authors.</i
                      >
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/indjcst" },
        { id: 2, name: "Aim & Scope", to: "/indjcst/aim-scope" },
        { id: 3, name: "Paper Submission", to: "/indjcst/paper-submission" },
        {id:4,name:"Guidance of EMS",to:"/indjcst/guidance-for-ems-indjcst"},
        {
          id: 5,
          name: "Paper Status",
          to: "/indjcst/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/indjcst/current-issue" },
        { id: 7, name: "Archives", to: "/indjcst/archives" },
        { id: 8, name: "Editorial Board", to: "/indjcst/editorial-board" },
        { id: 9, name: "Topics", to: "/indjcst/topics" },
        { id: 10, name: "Call for papers", to: "/indjcst/call-for-paper" },
        {
          id: 11,
          name: "Instruction for Authors",
          to: "/indjcst/instruction-for-authors",
        },



        {
          id: 12,
          name: "Ethics & Policies",
          to: "/indjcst/ethics-and-policies",
        },
        {
          id: 13,
          name: "Indexing & Abstracting",
          to: "/indjcst/indexing-abstracting",
        },
        { id: 14, name: "Impact Factor", to: "/indjcst/impact-factors" },
        {
          id: 15,
          name: "Article Processing Charges",
          to: "/indjcst/article-processing-charges",
        },
        { id: 16, name: "Downloads", to: "/indjcst/downloads" },
        { id: 17, name: "FAQs", to: "/indjcst/FAQs" },
        {
          id: 18,
          name: "Peer Review Policy",
          to: "/indjcst/peer-review-policy",
        },
        {
          id: 19,
          name: "Contact Us",
          to: "/indjcst/indjcst-contact",
        },

      ],
    };
  },
};
</script>
<style scoped>
h3 {
  font-size: 24px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
.price-span{
  color: #0000ff;
}
</style>
