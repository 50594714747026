<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Conference" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 mt-2">
            <div class="conferencelist-container" v-if="mainPage">
              <div class="row paperBox mb-4 p-3">
                <div class="header-container row">
                  <h4 class="col-10 pr-2">{{ react24.title }}</h4>
                  <div class="col-2">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="viewConferencePapers()"
                      >View Papers</b-button
                    >
                  </div>
                  <h5 class="col-10 pr-2">{{ react24.name }}</h5>
                </div>
                <div class="details-container mt-4 row">
                  <div class="author-details pb-3">
                    <p><b>Organized By : </b>{{ react24.conductedBy }}</p>
                  </div>
                  <div class="submit-details pb-3">
                    <p><b>Conference Date : </b>{{ react24.conferenceDate }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="paperlist-container" v-if="!mainPage && paperpage">
              <div class="row paperBox mb-4 p-2 text-center">
                <h5>2024 | Volume 4 - Issue 3 | REACT'24</h5>
              </div>
              <div
                class="row paperBox mb-3"
                v-for="items in papersListFromJs"
                :key="items.id"
              >
                <div class="col-9 p-3">
                  <h4>{{ items.title }}</h4>
                  <p><b>Author:</b> {{ items.author }}</p>
                  <p><b>Article Type:</b> {{ items.articleType }}</p>
                  <p><b>Pages:</b> {{ items.pages }}</p>
                </div>
                <div class="col-3 p-3">
                  <!-- <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="viewPaperDetails(items.id)"
                    >Paper Details</b-button
                  > -->
                  <b-button
                    class="col-8 mb-2"
                    variant="outline-primary"
                    size="sm"
                    @click="downloadPDF()"
                    >Download PDF</b-button
                  >
                  <!-- <b-button
                    class="col-8"
                    variant="outline-primary"
                    size="sm"
                    @click="
                      googleSearch(
                        'https://www.google.com/search?q=' + items.title
                      )
                    "
                    >Google</b-button
                  > -->
                </div>
              </div>
            </div>
            <!-- <div class="paperdetails-container" v-if="!mainPage && paperPage">
              <div class="row paperBox mb-4 p-3">
                <div class="header-container row">
                  <h4 class="col-10 pr-2">{{ paperTitle }}</h4>
                  <div class="col-2">
                    <b-button
                      variant="outline-primary"
                      size="sm"
                      @click="downloadPDF()"
                      >Download PDF</b-button
                    >
                  </div>
                </div>
                <div class="details-container mt-4 row">
                  <div class="author-details pb-3">
                    <p><b>Author(s) : </b>{{ paperAuthor }}</p>
                  </div>
                  <div class="submit-details pb-3">
                    <p><b>Submission-Year,Month : </b>{{ paperMonth }}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Pages : </b>{{ paperPages }}</p>
                  </div>
                  <div class="author-details pb-3">
                    <p><b>Article Type : </b>{{ paperType }}</p>
                  </div>
                  <div class="author-details">
                    <p><b>DOI : </b>{{ paperDoi }}</p>
                  </div>
                </div>
              </div>
              <div class="row p-3 paperBox">
                <h4 class="mb-2">Abstract</h4>
                <p class="abstract-details">{{ paperAbs }}</p>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
import React24 from "../../../util/conference-react24";

export default {
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {
          id: 4,
          name: "Guidance of EMS",
          to: "/ijsreat/guidance-for-ems-ijsreat",
        },
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
      mainPage: true,
      papersListFromJs: React24.papersList,
      paperPage: false,
      selectedId: null,
      paperTitle: "",
      paperAuthor: "",
      paperMonth: "",
      paperPages: "",
      paperType: "",
      paperDoi: "",
      paperAbs: "",
      paperUrl: "",
      react24: {
        title: "REACT-2024",
        name: "Recent Advances in Civil Engineering and Technology-2024",
        conductedBy: "Vidya Academy of Science and Technology, Kerala, India",
        conferenceDate: "19 & 20 June2024",
      },
    };
  },
  watch: {
    $route: function () {
    //   if ("paperid" in this.$route.query) {
    //     this.viewPaperDetails(this.$route.query.paperid);
    //   } else if ("id" in this.$route.query) {
    //     this.viewConferenceDetails(this.$route.query.id);
    //     //this.viewPaperDetails(this.$route.query.id)
    //   } else {
    //     this.mainPage = true;
    //     this.paperPage = false;
    //   }
    if('id' in this.$route.query){
            this.paperpage= true
            this.mainPage = false
        } else{
            this.paperpage= false
            this.mainPage = true
        }
    },
  },
  methods: {
    downloadPDF: function () {
        console.log('download')
    //   if (paperURL) {
    //     window.open(paperURL, "_blank");
    //   } else {
    //     window.open(this.paperUrl, "_blank");
    //   }
    },
    // googleSearch(url) {
    //   window.open(url, "_blank");
    // },
    // viewPaperDetails(pid) {
    //   this.$router.push({ path: "conference", query: { paperid: pid } });
    //   this.paperPage = true;
    //   this.mainPage = false;
    //   this.selectedId = pid;

    //   const detail = this.papersListFromJs.find((d) => d.id === pid);

    //   this.paperTitle = detail.title;
    //   this.paperAuthor = detail.author;
    //   this.paperMonth = detail.submissionYearMonth;
    //   this.paperPages = detail.pages;
    //   this.paperType = detail.articleType;
    //   this.paperDoi = detail.doi;
    //   this.paperAbs = detail.abstract;
    //   this.paperUrl = detail.paperUrl;
    // },
    viewConferencePapers: function () {
      this.$router.push({ path: "ijsreat-conference", query: { id: "react24" } });
      this.mainPage = false;
      this.paperpage = true 
    },
    // viewConferenceDetails: function () {
    //   this.mainPage = false;
    //   if ("paperid" in this.$route.query) {
    //     this.paperPage = true;
    //     this.viewPaperDetails(this.$route.query.paperid);
    //   } else {
    //     this.paperPage = false;
    //   }
    // },
  },
  mounted: function () {
    // if ("paperid" in this.$route.query) {
    //   this.viewPaperDetails(this.$route.query.paperid);
    // } else if ("id" in this.$route.query) {
    //   this.viewConferenceDetails(this.$route.query.id);
    //   //this.viewPaperDetails(this.$route.query.id)
    // } else {
    //   this.mainPage = true;
    //   this.paperPage = false;
    // }
  },
};
</script>

<style scoped>
.issueButton {
  cursor: pointer;
}
.box {
  background: #0000ff;
  border-radius: 8px;
}
.box p {
  color: #fff;

  text-align: center;
  padding: 6px;
}

.btn-outline-primary {
  color: #0000ff;
  border-color: #0000ff;
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: #0000ff;
  background: #0000ff;
}

.paperBox {
  /* border: 2px solid #EEEDE7; */
  word-wrap: break-word;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p {
  margin-bottom: 5px;
  text-align: left;
}
h5 {
  margin-bottom: 5px;
  margin-top: 5px;
}
h6 {
  margin-bottom: 5px;
  margin-top: 5px;
}
.abstract-details {
  white-space: pre-wrap;
}

@media screen and (max-width: 991px) {
  .btn-sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 512px) {
  .btn-sm {
    font-size: 10px !important;
  }
}
</style>
