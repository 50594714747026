<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Contact Us" />
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div
              class="containers"
              style="display: flex; flex-direction: row; margin-bottom: 20px"
            >
              <div
                class="editorialBox p-4"
                style="margin-right: 2%; width: 49%"
              >
                <p>
                  <b>Publisher:</b> Fifth Dimension research Publication (P)
                  Ltd. No: 38D5F, Rajaji nagar, Ariyalur-621713. Tamilnadu,
                  India.
                </p>
                <p><b>Website:</b>www.fdrpjournals.org</p>
                <p><b>Phone : </b>+91 98405 21421</p>
                <p><b>Email : </b><i> fdrpjournals@gmail.com</i></p>
              </div>
              <div class="editorialBox p-4" style="width: 49%">
                <p>
                  <b>Editor-in-chief:</b>
                </p>
                <p>
                  Dr. S. Sivaganesan Professor & Head in EEE House No:55-E
                  Kamaraj Nagar, 2nd Street, Ariyalur-621713.
                </p>

                <p><b>Phone :</b> +91 98405 21421</p>
                <p><b>Email : </b><i>editorincheif@ijsreat.com</i></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
    
    <script>
import Navbar from "../../Layout/Navbar.vue";
import Footer from "../../Layout/Footer";
import PageTitle from "../../Common/PageTitle";
export default {
  name: "ProjectsDetailsContent",
  components: {
    Navbar,
    Footer,
    PageTitle,
  },
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>
    <style scoped lang="scss">
h3 {
  font-size: 24px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
ul li {
  list-style: none;
}

.mission-of-journal ul li {
  margin-left: -12px;
}
.editorialBox {
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media screen and (max-width: 992px) {
  .containers {
    display: flex;
    flex-direction: column !important;
    .editorialBox {
      width: 100% !important;
      margin-bottom: 20px;
    }
  }
}
</style>
    