<template>
  <div>
    <Navbar />
    <PageTitle pageTitle="Download Forms" />
    <div class="journal-page-area pb-100 " style="padding-top: 50px;">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
           <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table" > 
              <div class="" style="text-align:center;padding-top: 80px;"> 
             <h6> Manuscript Template</h6>
             <button @click="getManuScript(this.ManuScriptUrl)" class="btn m-2">Download</button>
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 indexingImage single-pricing-table">
              <div  style="text-align:center;padding-top: 80px;"> 
                <h6 class="justify content center"> Copyright Form</h6>
             <button @click="getCopyRightForm(this.CopyRightFormUrl)" class="btn m-2">Download</button> 

              </div>
        
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script>
  import Navbar from "../../Layout/Navbar.vue"
  import Footer from "../../Layout/Footer";
  import PageTitle from "../../Common/PageTitle";
  export default {
    name: "ProjectsDetailsContent",
    components: {
       Navbar,
      Footer,
      PageTitle,
    },
    data: function () {
      return {
        ManuScriptUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/manuscripttemplate/IJSREAT-Manuscript-Template.docx',
      CopyRightFormUrl:'https://fdrpjournals.s3.ap-south-1.amazonaws.com/main/3/copyrightform/IJSREAT_Registration+%26Copyright-Form.pdf',
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
      };
    },
    methods: {
    getManuScript: function(getUrl) {
      window.open(getUrl, '_blank')
    },
    getCopyRightForm: function(getForm) {
      window.open(getForm, '_blank')
    }
  }
  };
  </script>
  <style scoped>
.indexingImage  {
  height: 250px;
  width: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.indexingImage {
 margin:10px;
 
}
.btn {
  color:#0000ff;
  border:1px solid #0000ff ;
  background-color: #fff;
}
.btn:hover {
  color:#fff;
  background-color: #0000ff;
}
  
 
  </style>
  