<template>
  <div>
    <div class="journal-page-area pb-100" style="padding-top: 50px">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-lg-3">
            <div class="menu-wrapper">
              <div class="menu-header">
                <h4>IJSREAT</h4>
              </div>
              <ul class="no-style">
                <li v-for="sublink in links" :key="sublink.id">
                  <i class="fas fa-chevron-right"></i>
                  <router-link :to="sublink.to">
                    {{ sublink.name }}
                    <span></span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="container">
              <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=2">
                <button class="default-btn mr-3">Submit your Article<span></span></button>
              </a>
                </div>
              </div>
              <div class="row">
                <div class="international-journals pt-4 journal-home">
                  <h3 class="headingThree">
                    Welcome To IJSREAT :
                  </h3>
                  <p class="ps-3">
                      The International Journal of Scientific Research in
                      Engineering & Technology (IJSREAT) provides a
                      multidisciplinary passage for investigators, managers,
                      professionals, practitioners, and students around the
                      globe to publish high-quality, peer-reviewed articles on
                      all theoretical and experimental aspects of Engineering
                      and technology
                    </p>
                  <div class="ps-3">
                    <div class="particulars">
                      <h5>Journal particulars :</h5>
                      <div class="row">
                        <div class="col">
                          <ul>
                            <li>
                              <p>
                                <b>Title :</b>International Journal Of
                                Scientific Research In Engineering & Technology
                              </p>
                            </li>
                            <li>
                              <p><b>Frequency :</b>Bi-Monthly</p>
                            </li>
                            <li>
                              <p>ISSN :<b></b>2583-1240</p>
                            </li>
                            <li>
                              <p>
                                <b>Publisher :</b>Fifth Dimension Research
                                Publication (P) Ltd.
                              </p>
                            </li>
                            <li>
                              <p>
                                <b> Editor-in-Chief :</b> Dr.S.Sivaganesan
                                (Professor & Head)
                              </p>
                            </li>
                            <li>
                              <p>
                                <b>Copyright : </b>Fifth Dimension Research
                                Publication (P) Ltd.
                              </p>
                            </li>
                            <li>
                              <p><b>Starting Year :</b>2021</p>
                            </li>
                          </ul>
                        </div>
                        <div class="col">
                          <ul>
                            <li>
                              <p><b>Subject : </b>Engineering</p>
                            </li>
                            <li>
                              <p><b>Language :</b>English</p>
                            </li>
                            <li>
                              <p><b>Publication Format :</b>Online</p>
                            </li>
                            <li>
                              <p><b>Email Id : </b>editorinchief@ijsreat.com</p>
                            </li>
                            <li>
                              <p><b>Mobile No :</b>+91 9840521421</p>
                            </li>
                            <li>
                              <p><b>Website : </b>www.ijsreat.com</p>
                            </li>
                            <li>
                              <p>
                                <b>Address :</b>Fifth Dimension research
                                Publication (P) Ltd. No: 38D5F, Rajaji nagar,
                                Ariyalur-621713. Tamilnadu, India.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container pt-3">
                  <h5 class="ps-3 pb-2">Why Choose IJSREAT Journal :</h5>
                  <div class="row ps-3">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <ul>
                        <li><p>Strict Plagiarism Policy</p></li>
                        <li><p>Good Impact Factor</p></li>
                        <li><p>Peer Reviewed Journal</p></li>
                        <li><p>Open Access Journal</p></li>
                        <li><p>Reputed Editorial Board</p></li>
                        <li><p>Rapid/Fast Track Publication</p></li>
                        <li><p>Fully Archived Journal</p></li>
                        <li><p>High Visibility & Indexing</p></li>
                        <li><p>High Impact Factor Journal</p></li>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <ul>
                        <li><p>Response Will Be Given Within 12 Hours.</p></li>
                        <li>
                          <p>The Paper Will Be Reviewed Within Two Days.</p>
                        </li>
                        <li>
                          <p>
                            Author Will Get Their Acceptance/Rejection
                            Notification Within 3 Days Of Submitting Paper.
                          </p>
                        </li>
                        <li>
                          <p>
                            All The Accepted Papers Will Be Open Accessible With
                            Full PDF Download.
                          </p>
                        </li>
                        <li>
                          <p>
                            All Authors Will Get Soft Copy Of Certificate,
                            Letter Of Acceptance, Reviewer Evaluation Grading
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between pt-4">
                <div class="submit-your-article">
                  <p> Submit your Article by Using
                  </p>
                  <p>FDRP-Editorial management system </p>
                </div>
                <div class="pt-2">
                  <a href="http://editorial.fdrpjournals.org/login?journal=2">
                <button class="default-btn mr-3">Submit your Article<span></span></button>
              </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsDetailsContent",
  components: {},
  data: function () {
    return {
      links: [
        { id: 1, name: "Home", to: "/ijsreat" },
        { id: 2, name: "Aim & Scope", to: "/ijsreat/aim-scope" },
        {
          id: 3,
          name: "Paper Submission",
          to: "/ijsreat/paper-submission",
        },
        {id:4,name:"Guidance of EMS",to:"/ijsreat/guidance-for-ems-ijsreat"},
        {
          id: 5,
          name: "Paper Status",
          to: "/ijsreat/paper-status",
        },
        { id: 6, name: "Current Issue", to: "/ijsreat/current-issue" },
        { id: 7, name: "Archives", to: "/ijsreat/archives" },
        { id: 8, name: "Conference", to: "/ijsreat/ijsreat-conference" },
        {
          id: 9,
          name: "Editorial Board",
          to: "/ijsreat/editorial-board",
        },
        { id: 10, name: "Topics", to: "/ijsreat/topics" },
        { id: 11, name: "Call for papers", to: "/ijsreat/call-for-paper" },
        {
          id: 12,
          name: "Instruction for Authors",
          to: "/ijsreat/instruction-for-authors",
        },

        {
          id: 13,
          name: "Ethics & Policies",
          to: "/ijsreat/ethics-and-policies",
        },
        {
          id: 14,
          name: "Indexing & Abstracting",
          to: "/ijsreat/indexing-abstracting",
        },
        { id: 15, name: "Impact Factor", to: "/ijsreat/impact-factors" },
        {
          id: 16,
          name: "Article Processing Charges",
          to: "/ijsreat/article-processing-charges",
        },
        {
          id: 17,
          name: "Downloads",
          to: "/ijsreat/downloads",
        },
        { id: 18, name: "FAQs", to: "/ijsreat/FAQs" },
        {
          id: 19,
          name: "Peer Review Policy",
          to: "/ijsreat/peer-review-policy",
        },
        {
          id: 20,
          name: "Contact Us",
          to: "/ijsreat/ijsreat-contact",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.containerbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 120px 50px 120px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 30px;

  .imageBox {
    width: 120px;
    height: auto;
    margin-right: 50px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 1199px) {
  .containerbox {
    flex-direction: column;

    .imageBox {
      margin-bottom: 50px;
    }

    .contentBox {
      margin-left: 0px !important;
    }
  }
}
</style>
