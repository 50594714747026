<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Indian Journal of Electronics and Communication Engineering(INDJECE)" pageSubTitle="An Open Access , Peer-reviewed, Refereed Journal, DOI:10.59256/indjece
" />
        <Indjece />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Indjece from '../Journals/IndjeceHomePage'
import Footer from '../Layout/Footer'

export default {
    components: {
        Navbar,
        PageTitle,
        Indjece,
        Footer,
    }
}
</script>
